import { FC, useMemo, useCallback, useState } from 'react'
import { Spin, message, Typography, Grid, Card } from 'antd'
import _ from 'lodash'
import styled from 'styled-components'
import { Swiper, SwiperSlide } from 'swiper/react'
import SwiperCore, { Pagination, Mousewheel, A11y, History } from 'swiper'

import 'swiper/swiper.scss'
import 'swiper/components/pagination/pagination.scss'

import StyledContainer from '@root/ic-ui-react/StyledContainer'
import useFetchDishes from '@ic-api/menu/useFetchDishes'
import Dish from '@models/dish'

import CategoryView from './CategoryView'

SwiperCore.use([Pagination, A11y, Mousewheel, History])
interface CardProps {
  active: boolean
}

interface Category {
  key: string
  value: string
  text: string
}

const CATEGORIES: Category[] = [
  {
    key: '1',
    value: 'Appetizers',
    text: 'Appetizers',
  },
  {
    key: '2',
    value: 'Salads',
    text: 'Salads',
  },
  {
    key: '3',
    value: 'Pita Sandwiches',
    text: 'Pita Sandwiches',
  },
  {
    key: '4',
    value: 'Soups',
    text: 'Soups',
  },

  {
    key: '5',
    value: 'Pitaki Specials',
    text: 'Pitaki Specials',
  },
  {
    key: '6',
    value: 'Plates',
    text: 'Plates',
  },
  {
    key: '7',
    value: 'Sides',
    text: 'Sides',
  },
  {
    key: '8',
    value: 'Desserts',
    text: 'Desserts',
  },
  {
    key: '9',
    value: 'Drinks',
    text: 'Drinks',
  },
  {
    key: '10',
    value: 'Monthly Special',
    text: 'Monthly Special',
  },
  {
    key: '11',
    value: 'Catering',
    text: 'Catering',
  },
]
const StyledCard = styled(Card)<CardProps>`
  color: ${(props) => props.active && '#1890ff'};
  border-color: ${(props) => props.active && '#1890ff'};
  background-color: ${(props) => props.active && '#f0f8ff'};
`

const FetchDishes: FC = () => {
  const { loading, dishes, error } = useFetchDishes()

  return loading ? (
    <Spin size="large" tip="LOADING..." style={{ display: 'block', marginLeft: 'auto' }} />
  ) : (
    <>
      {error ? (
        message.error(error)
      ) : (
        <>{dishes && dishes.length > 0 && <MenuDisplay dishes={dishes} />}</>
      )}
    </>
  )
}

const MenuDisplay: FC<{ dishes: Dish[] }> = ({ dishes }) => {
  const { md, lg, xl, xxl } = Grid.useBreakpoint()
  const [dishesByCategory, setDishesByCategory] = useState<Dish[]>(
    _.sortBy(_.filter(dishes, ['category', 'Appetizers']), 'subcategory')
  )
  const uniqDishes = useMemo<Dish[]>(() => _.uniqBy(dishes, 'category'), [dishes])
  const [selectedCategory, setSelectedCategory] = useState<string>('Appetizers')

  const handleChangeCategory = useCallback(
    (e: React.MouseEvent<HTMLDivElement>) => {
      const userSelectedCategory = e.currentTarget.dataset.category
      setSelectedCategory(userSelectedCategory || 'Appetizers')
      setDishesByCategory(
        _.sortBy(_.filter(dishes, ['category', userSelectedCategory]), 'subcategory')
      )
    },
    [dishes]
  )

  return (
    <StyledContainer fluid>
      <StyledContainer fluid relax>
        <Typography.Title level={1}>MENU</Typography.Title>
      </StyledContainer>
      <Swiper
        spaceBetween={10}
        centeredSlides
        centeredSlidesBounds
        slidesPerView={md || lg || xl || xxl ? 4.2 : 2.2}
        mousewheel={{ forceToAxis: true }}
        pagination
        style={{ padding: '10px' }}
      >
        {CATEGORIES.map(({ key, value, text }) => (
          <SwiperSlide key={key}>
            <StyledCard
              hoverable
              data-category={value}
              active={selectedCategory === value}
              onClick={handleChangeCategory}
            >
              <Typography.Paragraph style={{ margin: 0, textAlign: 'center' }} ellipsis>
                {text}
              </Typography.Paragraph>
            </StyledCard>
          </SwiperSlide>
        ))}
        <br />
        <br />
      </Swiper>

      <CategoryView dishes={dishesByCategory} />
    </StyledContainer>
  )
}

export default FetchDishes
